<template>
  <div class="form">
    <div class="file__row">
      <div
        class="file__block"
        v-for="(item, index) in imgGallery"
        :key="index"
        @click.prevent="btnGallery(index, item)"
        :style="'background-image: url(' + item.thumbUrl + ')'"
      ></div>
    </div>
    <div class="file__download">
      <a 
        download
        v-for="(item, index) in files"
        :key="index"
        :href="item.url" 
        target="_blank"
      >
        <i class="icon-download"></i><span style="flex:1">{{item.name}}</span></a
      >
    </div>
    <Gallery
      :openGallery="openGallery"
      @close="openGallery = false"
      :img="{ list: imgGallery, index }"
    />
  </div>
</template>


<style lang="scss">
</style>



<script>
import Gallery from "@/components/app/Gallery";

export default {
  props: ["id"],
  data: () => ({
    canEdit: true,
    urlDomain: "",
    imgGallery: [],
    files: [],
    openGallery: false,
    index: 0,
  }),

  async created() {
    this.canEdit = this.id.canEdit;

    this.imgGallery = this.id.photos;
    this.files = this.id.files;
  },

  methods: {
    btnGallery(index) {
      this.index = index;
      this.openGallery = true;
    },
  },
  

  components: {
    Gallery,
  },
};
</script>