<template>
  <div class="form">

    <p class="info_containers__title">{{$root.langText.total}}:</p>
      <ul class="info_containers">
        <li>
          <span>{{$root.langText.changes.income}}</span>
          <b>{{Math.round(id.services_income)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.consumption}}</span>
          <b>{{Math.round(id.services_outgo)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.profit}}</span>
          <b>{{Math.round(id.services_profit)}}</b>
        </li>
      </ul>
  
    <Accordion 
      :data="id" 
      :name="{mode: 'services', bd_name: 'services', modPage: 'orders'}" 
      :modCreate="'order'" 
      :canEdit="canEdit"
      @update="$emit('updateData')" />  
  </div>
</template>


<style lang="scss">

</style>



<script>
import Accordion from '@/components/agreementsOther/Accordion'

export default {
  props: ['id'],
  data: () => ({
    canEdit: true,
    
  }),



  
  async created() {
    this.canEdit = this.id.canEdit && this.$root.edit.can_edit_plan_calculation

  },
  components: {
    Accordion
  }
}
</script>