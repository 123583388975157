<template>
  <div>
    <form class="form">
      <h2>{{ $root.langText.info }}</h2>

      <div class="form__row" :class="$route.params.id ? 'form__row-70' : ''">
        <div class="form__input" v-if="$route.params.id">
          <input type="text" disabled v-model="fields.number" />
          <span>{{ $root.langText.input.number }}</span>
        </div>

        <div class="form__input--plagin">
          <DataInput
            :data="{
              data: fields.date,
              input: 'date',
              edit: $root.edit.can_edite_data_orders,
              name: $root.langText.input.date,
            }"
            @resultData="dataInput"
          />
        </div>
      </div>

      <div
        class="form__checkbox"
        v-if="fields.status_id == '5' && !fields.fraftIsHidden ? false : true"
      >
        <label>
          <input
            type="checkbox"
            :disabled="fields.status_id == '5'"
            v-model="fields.fraftIsHidden"
          />
          <span>{{ $root.langText.necessaryInformation }}</span>
        </label>
      </div>

      <Autocomplete
        :data="{
          name: $root.langText.customer,
          mod: 'clients',
          key: 'client',
          input: fields.client,
          inputId: fields.client_id,
        }"
        @result="autocompleteResult"
      />

      <div class="form__input">
        <select v-model="fields.status_id">
          <option v-for="(type, index) in status" :key="index" :value="type.id">
            {{ type.name }}
          </option>
        </select>
        <span>{{ $root.langText.input.status }}</span>
        <div class="icon icon-arrow-down"></div>
      </div>

      <div class="form__row form__row-70">
        <div class="form__input">
          <select v-model="fields.type_id" @change="changeType">
            <option
              v-for="(type, index) in types"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.type }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>

        <div class="form__input">
          <select v-model="fields.select">
            <option
              v-for="(select, index) in selects"
              :key="index"
              :value="select.id"
            >
              {{ select.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.view }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>
      </div>

      <DataInput
        v-if="!isFreight"
        :data="{
          data: fields.dateAdded,
          input: 'dateAdded',
          name: $root.langText.input.dateAdded,
        }"
        @resultData="dataInput"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.mainCarrier,
          mod: 'counterparties',
          key: 'main_carrier',
          input: fields.main_carrier,
          inputId: fields.main_carrier_id,
        }"
        @result="autocompleteResult"
        v-if="!this.$route.params.id && !isFreight"
      />




      <div v-if="isFreight">
        <div class="form__input">
          <p class="form__input__textarea-p">{{ fields.cargo }}</p>
          <textarea class="form__input__textarea" v-model="fields.cargo" />
          <span>{{ $root.langText.input.cargo }}</span>
        </div>

        <Autocomplete
          :data="{
            name: $root.langText.changes.organization,
            mod: 'all',
            modType: 'org',
            key: 'organization',
            input: fields.organization,
            inputId: fields.organization_id,
          }"
          @result="autocompleteResult"
        />

        <Autocomplete
          :data="{
            name: $root.langText.input.legalEntity2,
            mod: 'legalPersons',
            key: 'legalEntity',
            input: fields.legalEntity,
            inputId: fields.legalEntity_id,
          }"
          @result="autocompleteResult"
        />

        <div class="form__row form__row-50">
          <Autocomplete
            :data="{
              name: $root.langText.input.line,
              mod: 'all',
              modType: 'line',
              key: 'line',
              input: fields.line,
              inputId: fields.line_id,
            }"
            @result="autocompleteResult"
          />

          <DataInput
            :data="{
              data: fields.eta,
              input: 'eta',
              name: $root.langText.input.eta,
            }"
            @resultData="dataInput"
          />
        </div>

        <Autocomplete
          :data="{
            name: $root.langText.input.shipwreck,
            mod: 'all',
            modType: 'courtAction',
            key: 'courtAction',
            input: fields.courtAction,
            inputId: fields.courtAction_id,
          }"
          @result="autocompleteResult"
        />

        <div class="form__input">
          <input type="text" v-model="fields.booking" />
          <span>{{ $root.langText.input.booking }}</span>
        </div>

        <div class="form__input">
          <input type="text" v-model="fields.konosament" />
          <span>{{ $root.langText.input.consignment }}</span>
        </div>

        <div>
          <div class="form__row form__row-50">
            <div class="form__input">
              <select v-model="fields.statusMBL">
                <option
                  v-for="(select, index) in statusMBL"
                  :key="index"
                  :value="select.id"
                >
                  {{ select.name }}
                </option>
              </select>
              <span>{{ $root.langText.input.statusMbl }}</span>
              <div class="icon icon-arrow-down"></div>
            </div>

            <DataInput
              :data="{
                data: fields.dataMBL,
                input: 'dataMBL',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>

          <div class="form__input">
            <input type="text" v-model="fields.homeBL" />
            <span>{{ $root.langText.input.homeBl }}</span>
          </div>
        </div>

        <div>
          <h2>{{ $root.langText.input.deliveryWay }}</h2>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.poo,
                  mod: 'city',
                  key: 'poo',
                  input: fields.poo,
                  inputId: fields.poo_id,
                  img: id.poo_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.pooData,
                  input: 'pooData',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.pol,
                  mod: 'city',
                  key: 'pol',
                  input: fields.pol,
                  inputId: fields.pol_id,
                  img: id.pol_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.polData,
                  input: 'polData',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.pot,
                  mod: 'city',
                  key: 'pot',
                  input: fields.pot,
                  inputId: fields.pot_id,
                  img: id.pot_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.potData,
                  input: 'potData',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.pot2,
                  mod: 'city',
                  key: 'pot2',
                  input: fields.pot2,
                  inputId: fields.pot2_id,
                  img: id.pot2_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.pot2Data,
                  input: 'pot2Data',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.pod,
                  mod: 'city',
                  key: 'pod',
                  input: fields.pod,
                  inputId: fields.pod_id,
                  img: id.pod_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.podData,
                  input: 'podData',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>

          <div class="form__row form__row-50">
            <div style="width: 68%">
              <Autocomplete
                :data="{
                  name: $root.langText.input.fd,
                  mod: 'city',
                  key: 'fd',
                  input: fields.fd,
                  inputId: fields.fd_id,
                  img: id.fd_country_code,
                }"
                @result="autocompleteResult"
              />
            </div>
            <div style="width: 32%">
              <DataInput
                :data="{
                  data: fields.fdData,
                  input: 'fdData',
                  name: $root.langText.input.date,
                }"
                @resultData="dataInput"
              />
            </div>
          </div>
        </div>
      </div>

      <h2>{{ $root.langText.input.employeesResponsible }}</h2>
      <Autocomplete
        :data="{
          name: $root.langText.input.managerClients,
          mod: 'team',
          key: 'manager',
          input: fields.manager,
          inputId: fields.manager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.filters.directionHead,
          mod: 'team',
          key: 'leader',
          input: fields.leader,
          inputId: fields.leader_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.logist,
          mod: 'team',
          key: 'logistician',
          input: fields.logistician,
          inputId: fields.logistician_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.documentManager,
          mod: 'team',
          key: 'documentManager',
          input: fields.documentManager,
          inputId: fields.documentManager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.assistant,
          mod: 'team',
          key: 'assistant',
          input: fields.assistant,
          inputId: fields.assistant_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.other,
          mod: 'team',
          key: 'other',
          input: fields.other,
          inputId: fields.other_id,
        }"
        @result="autocompleteResult"
      />

      <DataInput
        v-if="!isFreight"
        :data="{
          data: fields.dataWork,
          input: 'dataWork',
          name: $root.langText.input.datePerformed,
        }"
        @resultData="dataInput"
      />

      <!-- <a class="btn-save btn btn-primary" v-if="canEdit" href="#" @click.prevent="sendForm">{{$root.langText.save}}</a> -->
      <div
        style="display: flex; justify-content: space-between"
        v-if="$route.params.id"
      >
        <a
          class="btn btn-secondary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('copy')"
          >{{ $root.langText.copy }}</a
        >

        <a
          class="btn btn-primary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
      <div v-else>
        <a
          class="btn btn-primary btn-save"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
    </form>
    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
  </div>
</template>




<script>
import Autocomplete from "@/components/app/Autocomplete";
import Message from "@/components/app/Message";
import DataInput from "@/components/app/DataInput";

export default {
  props: ["id"],
  data: () => ({
    popup: { fade: false, mod: "Date", title: "", input: "" },
    canEdit: true,
    fields: {
      number: "",
      date: "",
      fraftIsHidden: false,
      customer: "",
      customer_id: "",
      basis: "",
      status: "Букiнг",
      client: "",
      client_id: "",
      type_id: "",
      select: "",
      dateAdded: "",
      resident: "",
      main_carrier: "",
      main_carrier_id: "",


      cargo: "",

      organization: "",
      organization_id: "",

      legalEntity: "",
      legalEntity_id: "",

      line: "",
      line_id: "",
      eta: "",

      courtAction: "",
      courtAction_id: "",

      booking: "",
      konosament: "",
      statusMBL: "",
      dataMBL: "",
      homeBL: "",
      // control: '',

      //
      poo: "",
      poo_id: "",
      pooData: "",

      pol: "",
      pol_id: "",
      polData: "",

      pot: "",
      pot_id: "",
      potData: "",

      pot2: "",
      pot2_id: "",
      pot2Data: "",

      pod: "",
      pod_id: "",
      podData: "",

      fd: "",
      fd_id: "",
      fdData: "",

      //




      manager: "",
      manager_id: "",
      assistant: "",
      assistant_id: "",
      logistician: "",
      logistician_id: "",
      documentManager: "",
      documentManager_id: "",
      leader: "",
      leader_id: "",
      other: "",
      other_id: "",
      dataWork: "",
      type_direction_color: "#8EB6E3",
      status_id: "1",
    },

    types: [],
    isFreight: false,
    status: [],

    selects: [],

    agreementsId: null,
    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },

    //select type
    params: {
      start: 0,
      limit: 20,
    },
  }),

  async created() {
    function boolean(val) {
      return val == 0 ? false : true;
    }
    if (this.$route.params.id || this.id.length !== 0) {
      this.canEdit = this.id.canEdit;
      if (this.$root.data.order.info) {
        this.agreementsId = this.$root.data.order.info;
      } else {
        this.agreementsId = this.id;
      }

      //data
      this.fields.number = this.id.order_number;
      this.fields.date = this.agreementsId.created_at;

      this.fields.fraftIsHidden = boolean(this.agreementsId.requires_informing);
      this.fields.client = this.agreementsId.user_name;
      this.fields.client_id = this.agreementsId.client_id;
      this.fields.basis = this.agreementsId.quote_info;

      this.fields.status_id = this.agreementsId.status_id;
      if (this.$route.params.id) {
        this.fields.type_id = this.agreementsId.type_id;
        this.fields.select = this.agreementsId.direction_type;
      } else {
        this.fields.type_id = this.agreementsId.order_type_id;
        this.fields.select = this.agreementsId.order_type_direction_id;
        this.fields.date = new Intl.DateTimeFormat("ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date());
        this.fields.client = this.agreementsId.client_name;
      }

      this.fields.dateAdded = this.agreementsId.date_of_load;

      this.fields.cargo = this.agreementsId.cargo;

      this.fields.organization = this.agreementsId.org_name;
      this.fields.organization_id = this.agreementsId.org_id;

      this.fields.legalEntity = this.agreementsId.legal_entity_name;
      this.fields.legalEntity_id = this.agreementsId.legal_entity_id;

      this.fields.line = this.agreementsId.line_name;
      this.fields.line_id = this.agreementsId.line;

      this.fields.courtAction = this.agreementsId.vessel_name;
      this.fields.courtAction_id = this.agreementsId.vessel;

      this.fields.booking = this.agreementsId.BookingReference;
      this.fields.konosament = this.agreementsId.konosament;
      this.fields.statusMBL = this.agreementsId.bl_type;
      this.fields.dataMBL = this.agreementsId.bl_date;
      this.fields.eta = this.agreementsId.eta;
      this.fields.homeBL = this.agreementsId.konosament_home;

      this.fields.poo = this.agreementsId.poo_name;
      this.fields.poo_id = this.agreementsId.poo_id;
      this.fields.pooData = this.agreementsId.date_of_poo;

      this.fields.pol = this.agreementsId.pol_name;
      this.fields.pol_id = this.agreementsId.pol_id;
      this.fields.polData = this.agreementsId.date_of_pol;

      this.fields.pot = this.agreementsId.pot_name;
      this.fields.pot_id = this.agreementsId.pot_id;
      this.fields.potData = this.agreementsId.date_of_pot;

      this.fields.pot2 = this.agreementsId.pot2_name;
      this.fields.pot2_id = this.agreementsId.pot2_id;
      this.fields.pot2Data = this.agreementsId.date_of_pot2;

      this.fields.pod = this.agreementsId.pod_name;
      this.fields.pod_id = this.agreementsId.pod_id;
      this.fields.podData = this.agreementsId.date_of_pod;

      this.fields.fd = this.agreementsId.fd_name;
      this.fields.fd_id = this.agreementsId.fd_id;
      this.fields.fdData = this.agreementsId.date_of_fd;
      
      this.fields.manager = this.agreementsId.manager_name;
      this.fields.manager_id = this.agreementsId.manager_id;
      this.fields.assistant = this.agreementsId.assistant_name;
      this.fields.assistant_id = this.agreementsId.assistant_id;
      this.fields.logistician = this.agreementsId.op_manager_name;
      this.fields.logistician_id = this.agreementsId.op_manager_id;
      this.fields.documentManager = this.agreementsId.doc_support_manager_name;
      this.fields.documentManager_id = this.agreementsId.doc_support_manager_id;
      this.fields.leader = this.agreementsId.head_of_name;
      this.fields.leader_id = this.agreementsId.head_of_id;
      this.fields.other = this.agreementsId.other_manager_name;
      this.fields.other_id = this.agreementsId.other_manager_id;
      this.fields.dataWork = this.agreementsId.work_completion_date;
      this.fields.main_carrier = this.agreementsId.main_carrier_name;
      this.fields.main_carrier_id = this.agreementsId.main_carrier_id;
    } else {
      var date = new Intl.DateTimeFormat("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date());
      var year = date.substr(6, 4);
      var day = date.substr(0, 2);
      var month = date.substr(3, 2);
      this.fields.date = year + "-" + month + "-" + day;

      if (localStorage.getItem("AgreementsClient") == "client") {
        if (localStorage.getItem("AgreementsClientId") !== "0") {
          this.fields.client = localStorage.getItem("AgreementsClientName");
          this.fields.client_id = localStorage.getItem("AgreementsClientId");
        }
      }
      if (localStorage.getItem("AgreementsCarrier") == "carrier") {
        this.fields.carrier = localStorage.getItem("AgreementsCarrierName");
        this.fields.carrier_id = localStorage.getItem("AgreementsCarrierId");
      }
    }

    if (this.$root.status.order) {
      this.status = this.$root.status.order;
    } else {
      const res2 = await this.$store.dispatch("getStatus");
      this.status = res2.data.list;
      this.$root.status.order = res2.data.list;
    }
    if (this.$root.type) {
      this.types = this.$root.type;
    } else {
      const res = await this.$store.dispatch("getOrdersTypes", this.params);
      this.types = res.data.list;
      this.$root.type = res.data.list;
    }
    if (this.$root.view) {
      this.selects = this.$root.view;
    } else {
      const res3 = await this.$store.dispatch("orderType");
      this.selects = res3.data.list;
      this.$root.view = res3.data.list;
    }
    if (this.$root.typeMBL) {
      this.statusMBL = this.$root.typeMBL;
    } else {
      const res4 = await this.$store.dispatch("typeMBL");
      this.statusMBL = res4.data.list;
      this.$root.typeMBL = res4.data.list;
    }
    var app = this;
    this.types.forEach(function (item, index) {
      if (app.fields.type_id == item.id) {
        if (item.tech_name == "") {
          app.isFreight = true;
        } else if(item.tech_name == "rio_auto") {
          app.isFreight = false;
        }
      }
    });
  },
  mounted() {},
  methods: {
    //autocomplete
    autocompleteResult(data) {
      this.fields[data.input] = data.name;
      this.fields[data.input + "_id"] = data.id;
      if (data.doc_menedger_id) {
        this.fields.documentManager = data.doc_menedger_name;
        this.fields.documentManager_id = data.doc_menedger_id;
      }
      if (data.input == "courtAction") {
        var app = this;
        var importIn = "";
        var exportOut = "";
        this.selects.forEach(function (item, index) {
          if (app.fields.select == item.id) {
            importIn = item.is_import;
            exportOut = item.is_export;
          }
        });
        if (importIn == 1) {
          this.fields.eta = data.data.dateIn;
          this.fields.polData = data.data.dateOut;
          this.fields.podData = data.data.dateIn;
        } else if (exportOut == 1) {
          this.fields.eta = data.data.dateOut;
          this.fields.polData = data.data.dateOut;
          this.fields.podData = data.data.dateIn;
        }
      }
    },
    //date
    dataInput(data) {
      this.fields[data.input] = data.date;
    },

    async changeType() {
      var app = this;
      if (this.$route.params.id || this.id.length !== 0) {
        var data = {
          id: this.id.id,
          type_id: app.fields.type_id,
        };
        this.message.status = 'ok';
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.save;
        await this.$store.dispatch("updateOrders", data);
      }
      this.types.forEach(function (item, index) {
        if (app.fields.type_id == item.id) {
          if (item.tech_name == "") {
            app.isFreight = true;
          } else if(item.tech_name == "rio_auto") {
            app.isFreight = false;
          }
        }
      });
      this.$emit("auto", app.isFreight);
    },

    //send
    async sendForm(mode) {
      function boolean(val) {
        return val == false ? 0 : 1;
      }
      //format date
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }

      var data = {
        created_at: date(this.fields.date) || "",
        requires_informing: boolean(this.fields.fraftIsHidden) || "",
        user_name: this.fields.client || "",
        client_id: this.fields.client_id || "",
        type_id: this.fields.type_id,
        direction_type: this.fields.select || "",
        date_of_load: date(this.fields.dateAdded) || "",
        // this.fields.resident =
        manager_id: this.fields.manager_id || "",
        assistant_id: this.fields.assistant_id || "",
        op_manager_id: this.fields.logistician_id || "",
        doc_support_manager_id: this.fields.documentManager_id || "",
        head_of_id: this.fields.leader_id || "",
        other_manager_id: this.fields.other_id || "",
        work_completion_date: date(this.fields.dataWork) || "",
        status_id: this.fields.status_id || "",
        
        cargo: this.fields.cargo || "",
        org_id: this.fields.organization_id || "",
        legal_entity_id: this.fields.legalEntity_id || "",
        line: this.fields.line_id || "",
        vessel: this.fields.courtAction_id || "",
        BookingReference: this.fields.booking || "",
        konosament: this.fields.konosament || "",
        bl_type: this.fields.statusMBL || "",
        bl_date: date(this.fields.dataMBL) || "",
        konosament_home: this.fields.homeBL || "",
        eta: date(this.fields.eta) || "",

        poo_id: this.fields.poo_id || "",
        date_of_poo: date(this.fields.pooData) || "",

        pol_id: this.fields.pol_id || "",
        date_of_pol: date(this.fields.polData) || "",

        pot_id: this.fields.pot_id || "",
        date_of_pot: date(this.fields.potData) || "",

        pot2_id: this.fields.pot2_id || "",
        date_of_pot2: date(this.fields.pot2Data) || "",

        pod_id: this.fields.pod_id || "",
        date_of_pod: date(this.fields.podData) || "",

        fd_id: this.fields.fd_id || "",
        date_of_fd: date(this.fields.fdData) || "",
      };


      if (this.$route.params.id && mode !== "copy") {
        data.id = this.agreementsId.id;
        if (this.$root.data.order.carrier) {
          await this.$store.dispatch(
            "updateOrders",
            this.$root.data.order.carrier
          );
        }
        if (this.$root.data.order.customer) {
          await this.$store.dispatch(
            "updateOrders",
            this.$root.data.order.customer
          );
        }
        var update = await this.$store.dispatch("updateOrders", data);
        this.$root.data.counterparties.info = "";
        this.$root.data.counterparties.customer = "";
        this.$root.data.counterparties.carrier = "";
        this.$emit("updateData");

        //messedge
        if (update.data == "ok") {
          this.message.status = update.data;
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.save;
        } else {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.error;
        }
      } else {
        // Если создание новой сделки
        // data.created_at = date(this.fields.date) || ''
        data.main_carrier_id = this.fields.main_carrier_id || "";

        if (this.agreementsId == null || mode == "copy") {
          if (mode !== "copy") {
            var createNew = await this.$store.dispatch("createOrders", data);
          } else {
            this.id.id = 0;
            var date = new Intl.DateTimeFormat("ru", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }).format(new Date());
            var year = date.substr(6, 4);
            var day = date.substr(0, 2);
            var month = date.substr(3, 2);
            this.fields.date = year + "-" + month + "-" + day;
            (this.id.carrier_freight = []),
              (this.id.carrier_freight2 = []),
              (this.id.kickbacks_carrier = []),
              (this.id.kickbacks_carrier2 = []),
              (this.id.requests_carrier = []),
              (this.id.requests_carrier2 = []),
              (this.id.orders_directions = []),
              (this.id.requests_clients = []),
              this.$router.push("/orders/new");
            return;
          }
        } else {
          data.quote_id = this.agreementsId.id;

          //Обновление катировки, чтобы была привязка
          var dataQ = {};
          dataQ.id = this.agreementsId.id;
          if (this.agreementsId.id > 0) {
            dataQ.order_id = newId;
            var updateQ = await this.$store.dispatch("updateQuotations", dataQ);
          }

          var mod = "orders";
          let app = this;
          //если перевозчика не меняли
          if (
            this.agreementsId.main_carrier_id == this.fields.main_carrier_id
          ) {
            data.special_conditions =
              this.agreementsId.special_conditions || "";
            data.container_type_id = this.agreementsId.container_type_id || "";
            data.carrier_id = this.agreementsId.carrier_id || "";
            data.carrier_manager_id =
              this.agreementsId.carrier_manager_id || "";
            data.driver_id = this.agreementsId.driver_id || "";
            data.truck_id = this.agreementsId.truck_id || "";
            data.trailer_id = this.agreementsId.trailer_id || "";
            data.lifting_capacity = this.agreementsId.lifting_capacity || "";
            data.volume = this.agreementsId.volume || "";
            data.legal_entity_id = this.agreementsId.legal_entity_id || "";
            data.special_conditions =
              this.agreementsId.special_conditions || "";
            data.rate_carrier = this.agreementsId.rate_carrier || "";
            data.border_crossing_id =
              this.agreementsId.border_crossing_id || "";
            data.is_overload = boolean(this.agreementsId.is_overload) || "";
            //если есть перегрузка
            if (this.agreementsId.is_overload) {
              data.overload_city_id = this.agreementsId.overload_city_id || "";
              data.overload_date = date(this.agreementsId.overload_date) || "";

              data.main_carrier2_id = this.agreementsId.main_carrier2_id || "";
              data.carrier2_id = this.agreementsId.carrier2_id || "";
              data.carrier_manager2_id =
                this.agreementsId.carrier_manager2_id || "";
              data.driver_id2 = this.agreementsId.driver_id2 || "";
              data.truck_id2 = this.agreementsId.truck_id2 || "";
              data.trailer_id2 = this.agreementsId.trailer_id2 || "";
              data.lifting_capacity2 =
                this.agreementsId.lifting_capacity2 || "";
              data.volume2 = this.agreementsId.volume2 || "";
              data.legal_entity2_id = this.agreementsId.legal_entity2_id || "";
              data.rate_carrier2 = this.agreementsId.rate_carrier2 || "";
            }
            var createNew = await this.$store.dispatch("createOrders", data);
            var newId = createNew.data.id;

            if (this.agreementsId.carrier_freight.length !== 0) {
              let arr = this.agreementsId.carrier_freight;
              arr.forEach(function (item, index) {
                var data = {
                  service_id: item.service_id || "",
                  comment: item.comment || "",
                  sum: item.sum || "",
                  currency_id: item.currency_id || "",
                  date: date(item.date) || "",
                  order_id: newId,
                };

                app.$store.dispatch("createCarrierFreight", { data, mod });
              });
            }
            if (this.agreementsId.carrier_freight2 !== 0) {
              let arr = this.agreementsId.carrier_freight2;
              arr.forEach(function (item, index) {
                var data = {
                  service_id: item.service_id || "",
                  comment: item.comment || "",
                  sum: item.sum || "",
                  currency_id: item.currency_id || "",
                  date: date(item.date) || "",
                  order_id: newId,
                };

                const create = app.$store.dispatch("createCarrierFreight2", {
                  data,
                  mod,
                });
              });
            }
            if (this.agreementsId.kickbacks_carrier !== 0) {
              let arr = this.agreementsId.kickbacks_carrier;
              arr.forEach(function (item, index) {
                var data = {
                  contPerson_str: item.contPerson_str,
                  contPerson_id: item.contPerson_id,

                  percent: item.percent,
                  card_number: item.card_number,
                  date: date(item.date),
                  sum: item.sum,
                  currency_id: item.currency_id,
                  exchange_rate: item.exchange_rate,
                  order_id: newId,
                };

                const create = app.$store.dispatch("createCarrierKickbacks", {
                  data,
                  mod,
                });
              });
            }
            if (this.agreementsId.kickbacks_carrier2 !== 0) {
              let arr = this.agreementsId.kickbacks_carrier2;
              arr.forEach(function (item, index) {
                var data = {
                  contPerson_str: item.contPerson_str,
                  contPerson_id: item.contPerson_id,

                  percent: item.percent,
                  card_number: item.card_number,
                  date: date(item.date),
                  sum: item.sum,
                  currency_id: item.currency_id,
                  exchange_rate: item.exchange_rate,
                  order_id: newId,
                };

                const create = app.$store.dispatch("createCarrierKickbacks2", {
                  data,
                  mod,
                });
              });
            }
            if (this.agreementsId.requests_carrier !== 0) {
              let arr = this.agreementsId.requests_carrier;
              arr.forEach(function (item, index) {
                var data = {
                  contractor_id: item.contractor_id,
                  contact_person_id: item.contact_person_id,
                  customer_freight_sum: item.customer_freight_sum,
                  driver_id: item.driver_id,
                  truck_id: item.truck_id,
                  trailer_id: item.trailer_id,
                  legal_entity_id: item.legal_entity_id,
                  carrier_id: item.carrier_id,
                  carrier_cont_pers_id: item.carrier_cont_pers_id,

                  with_type: 2,

                  doc_id: newId,
                  doc_type: "order",
                };

                const create = app.$store.dispatch("createRequests", {
                  data,
                  mod,
                });
              });
            }
            if (this.agreementsId.requests_carrier2 !== 0) {
              let arr = this.agreementsId.requests_carrier2;
              arr.forEach(function (item, index) {
                var data = {
                  contractor_id: item.contractor_id,
                  contact_person_id: item.contact_person_id,
                  customer_freight_sum: item.customer_freight_sum,
                  driver_id: item.driver_id,
                  truck_id: item.truck_id,
                  trailer_id: item.trailer_id,
                  legal_entity_id: item.legal_entity_id,
                  carrier_id: item.carrier_id,
                  carrier_cont_pers_id: item.carrier_cont_pers_id,

                  with_type: 2,

                  doc_id: newId,
                  doc_type: "order",
                };

                const create = app.$store.dispatch("createRequests", {
                  data,
                  mod,
                });
              });
            }
          } else {
            //если перевозчика поменяли
            var createNew = await this.$store.dispatch("createOrders", data);
            var newId = createNew.data.id;
          }
          function dateYearFirst(date) {
            if (date) {
              var year = date.substr(0, 4);
              var day = date.substr(8, 2);
              var month = date.substr(5, 2);
              var resultDate =
                new Date(year + "-" + month + "-" + day).getTime() / 1000;
              return resultDate;
            }
          }
          //если клиента не меняли
          if (this.agreementsId.client_id == this.fields.client_id) {
            if(this.agreementsId.quotes_directions.length !== 0){
              let arr = this.agreementsId.quotes_directions
              arr.forEach(function (item, index) {
                var dataDirections = {
                  date_delivery_from: dateYearFirst(item.date_delivery_from) || "",
                  date_delivery_to: dateYearFirst(item.date_delivery_to) || "",
                  shipment_date_from: dateYearFirst(item.shipment_date_from) || "",
                  shipment_date_to: dateYearFirst(item.shipment_date_to) || "",
                  contractor_id: item.contractor_id || "",
                  poo_id: item.poo_id || "",
                  loading_address: item.loading_address || "",
                  customs_address_from: item.customs_address_from || "",
                  fd_id: item.fd_id || "",
                  unloading_address: item.unloading_address || "",
                  customs_address_to: item.customs_address_to || "",
                  cargo: item.cargo || "",
                  download_method_id: item.download_method_id || "",
                  legal_entity_id: item.legal_entity_id || "",
                  order_id: newId,
                };
                app.$store.dispatch("createDirections", dataDirections);
              });
            }

            if (this.agreementsId.customer_freight !== 0) {
              let arr = this.agreementsId.customer_freight;
              arr.forEach(function (item, index) {
                var data = {
                  service_id: item.service_id || "",
                  comment: item.comment || "",
                  sum: item.sum || "",
                  currency_id: item.currency_id || "",
                  date: dateYearFirst(item.date) || "",
                  order_id: newId,
                };

                const create = app.$store.dispatch("createCustomerFreight", {
                  data,
                  mod,
                });
              });
            }

            if (this.agreementsId.kickbacks_customer !== 0) {
              let arr = this.agreementsId.kickbacks_customer;
              arr.forEach(function (item, index) {
                var data = {
                  contPerson_str: item.contPerson_str,
                  contPerson_id: item.contPerson_id,

                  percent: item.percent,
                  card_number: item.card_number,
                  date: dateYearFirst(item.date),
                  sum: item.sum,
                  currency_id: item.currency_id,
                  exchange_rate: item.exchange_rate,
                  order_id: newId,
                };

                const create = app.$store.dispatch("createKickbacks", {
                  data,
                  mod,
                });
              });
            }
            if (this.agreementsId.requests_clients !== 0) {
              let arr = this.agreementsId.requests_clients;
              arr.forEach(function (item, index) {
                var data = {
                  contractor_id: item.contractor_id,
                  contact_person_id: item.contact_person_id,
                  customer_freight_sum: item.customer_freight_sum,
                  driver_id: item.driver_id,
                  truck_id: item.truck_id,
                  trailer_id: item.trailer_id,
                  legal_entity_id: item.legal_entity_id,
                  carrier_id: item.carrier_id,
                  carrier_cont_pers_id: item.carrier_cont_pers_id,

                  with_type: 1,

                  doc_id: newId,
                  doc_type: "order",
                };

                const create = app.$store.dispatch("createRequests", {
                  data,
                  mod,
                });
              });
            }
          }
        }

        if (createNew.data.status == "error") {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.fillIn;
        } else {
          this.$router.push({
            name: "AgreementsCreateId",
            params: { id: createNew.data.id },
          });
        }
      }
    },
  },

  beforeDestroy() {
    function boolean(val) {
      return val == false ? 0 : 1;
    }
    //format date
    // function date(date) {
    //   if (date) {
    //     var year = date.substr(6, 4);
    //     var day = date.substr(0, 2);
    //     var month = date.substr(3, 2);
    //     var resultDate =
    //       new Date(year + "-" + month + "-" + day).getTime() / 1000;
    //     return resultDate;
    //   }
    // }
    this.$root.quote_id = "";
    //format date
    function dateSave(input, key) {
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }
      if (input) {
        if (
          input == "0000-00-00 00:00:00" ||
          input == null ||
          input == "0000-00-00" ||
          input == ""
        ) {
        } else {
          var date = new Date(date(input) * 1000);
          var newdate =
            date.getMonth() + 1 + "." + date.getDate() + "." + date.getFullYear();
          data[key] = newdate;
        }
      }
    }

    var data = {
      quote_id: (this.agreementsId ? this.agreementsId.quote_id : "") || "",
      requires_informing: boolean(this.fields.fraftIsHidden) || "",
      user_name: this.fields.client || "",
      client_id: this.fields.client_id || "",
      user_name: this.fields.client || "",
      type_id: this.fields.type_id,
      direction_type: this.fields.select || "",

      // this.fields.resident =
      manager_id: this.fields.manager_id || "",
      manager_name: this.fields.manager || "",
      assistant_id: this.fields.assistant_id || "",
      assistant_name: this.fields.assistant || "",
      op_manager_id: this.fields.logistician_id || "",
      op_manager_name: this.fields.logistician || "",
      doc_support_manager_id: this.fields.documentManager_id || "",
      doc_support_manager_name: this.fields.documentManager || "",
      head_of_name: this.fields.leader || "",
      head_of_id: this.fields.leader_id || "",
      other_manager_id: this.fields.other_id || "",
      other_manager_name: this.fields.other || "",
      status_id: this.fields.status_id || "",
      
      cargo: this.fields.cargo || "",
      org_id: this.fields.organization_id || "",
      org_name: this.fields.organization || "",
      legal_entity_id: this.fields.legalEntity_id || "",
      legal_entity_name: this.fields.legalEntity || "",
      line: this.fields.line_id || "",
      line_name: this.fields.line || "",
      vessel: this.fields.courtAction_id || "",
      vessel_name: this.fields.courtAction || "",
      BookingReference: this.fields.booking || "",
      konosament: this.fields.konosament || "",
      bl_type: this.fields.statusMBL || "",
      konosament_home: this.fields.homeBL || "",

      poo_id: this.fields.poo_id || "",
      poo_name: this.fields.poo || "",

      pol_id: this.fields.pol_id || "",
      pol_name: this.fields.pol || "",

      pot_id: this.fields.pot_id || "",
      pot_name: this.fields.pot || "",

      pot2_id: this.fields.pot2_id || "",
      pot2_name: this.fields.pot2 || "",

      pod_id: this.fields.pod_id || "",
      pod_name: this.fields.pod || "",

      fd_id: this.fields.fd_id || "",
      fd_name: this.fields.fd || "",
    };

    dateSave(this.fields.date, "created_at");
    dateSave(this.fields.dateAdded, "date_of_load");
    dateSave(this.fields.dataWork, "work_completion_date");
    dateSave(this.fields.dataMBL, "bl_date");
    dateSave(this.fields.eta, "eta");
    dateSave(this.fields.pooData, "date_of_poo");
    dateSave(this.fields.polData, "date_of_pol");
    dateSave(this.fields.potData, "date_of_pot");
    dateSave(this.fields.pot2Data, "date_of_pot2");
    dateSave(this.fields.podData, "date_of_pod");
    dateSave(this.fields.fdData, "date_of_fd");


    if (this.id) {
      data.id = this.id.id;
      data.order_number = this.id.order_number;
      if (this.id.quote_number) {
        data.quote_number = this.id.quote_number;
        data.quote_created_at = this.id.quote_created_at;
      }
    }
    this.$root.data.order.info = data;
  },

  components: {
    Autocomplete,
    Message,
    DataInput,
  },
};
</script>


